<!-- header-start -->
<header>
  <div class="header-area ">
    <div id="sticky-header" class="main-header-area">
      <div class="container-fluid">
        <div class="header_bottom_border">
          <div class="row align-items-center">
            <div class="col-10 col-xl-2 col-lg-2">
              <div class="logo">
                <a [routerLink]="['/']" class="color-dark">
                  <img src="assets/img/logo.png" alt="">
                  <span>Uzbekistan-tours</span>
                </a>
              </div>
            </div>
            <div class="col-xl-7 col-lg-7 d-none d-lg-block">
              <!--<div class="main-menu">
                <nav>
                  <ul id="navigation">
                    <li *ngFor="let item of menuItems; let num = index;">
                      <a [ngClass]="{'active': num === 0 }" [routerLink]="[item.link]">{{item.title}}</a>
                    </li>
                  </ul>
                </nav>
              </div>-->
            </div>
            <div class="col-xl-3 col-lg-3 d-none d-lg-block">
              <div class="social_wrap d-flex align-items-center justify-content-end">
              </div>
            </div>
            <div class="col-2 d-block d-lg-none">
              <i (click)="toggleMenu(menu)" class="mob-menu-icon fa fa-bars"></i>
            </div>
          </div>
          <div class="row d-lg-none">
            <div class="col-12 mob-main-menu close-menu" #menu>
              <nav>
                <ul class="navigation">
                  <li *ngFor="let item of menuItems; let num = index;">
                    <a [ngClass]="{'active': num === 0 }" [routerLink]="[item.link]">{{item.title}}</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</header>
<!-- header-end -->

<router-outlet></router-outlet>

<footer class="footer">
  <div class="copy-right_text">
    <div class="container">
      <div class="footer_border"></div>
      <div class="row">
        <div class="col-xl-12">
          <p class="copy_right text-center">
            Copyright &copy;2024 All rights reserved<br/>
          </p>
        </div>
      </div>
    </div>
  </div>
</footer>

<!-- Modal -->
<!--<div class="modal fade custom_search_pop" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="serch_form">
        <input type="text" placeholder="Search" >
        <button type="submit">search</button>
      </div>
    </div>
  </div>
</div>-->
