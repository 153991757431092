import {BrowserModule, HammerModule} from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { FrontAppModule } from '@app/front-app/src/lib/front-app.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FrontTemplateComponent } from '@app/front-app/src/lib/containers/front-template/front-template.component';

console.info('[ app ][ environment ]', environment);
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    HammerModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),

    FrontAppModule.forApp({
      publicUrl: environment.publicUrl,
      apiUrl: environment.apiUrl,
      websocketUrl: environment.websocketUrl
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production // Restrict extension to log-only mode
    }),
    RouterModule.forRoot(
      [{ path: '**', component: FrontTemplateComponent, pathMatch: 'full' }],
      { initialNavigation: 'enabledBlocking' }
    )
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
