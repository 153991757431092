import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { FrontTemplateComponent } from '@app/front-app/src/lib/containers/front-template/front-template.component';
import { PageNotFoundComponent } from "@app/front-app/src/lib/components/page-not-found/page-not-found.component";

export const routes: Route[] = [
    {
        path: '',
        component: FrontTemplateComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./blog/front-blog.module').then(m => m.FrontBlogModule),
                runGuardsAndResolvers: 'paramsOrQueryParamsChange'
            },
            {
                path: 'pages',
                loadChildren: () => import('@app/front-pages/front-pages.module').then(m => m.FrontPagesModule),
                runGuardsAndResolvers: 'paramsOrQueryParamsChange'
            },
            {
                path: 'tags',
                loadChildren: () => import('@app/front-tags/front-tags.module').then(m => m.FrontTagsModule),
                runGuardsAndResolvers: 'paramsOrQueryParamsChange'
            }
        ]
    },
    {
        path: '**',
        component: PageNotFoundComponent
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class FrontAppRoutingModule {
}
