import { ChangeDetectionStrategy, Component, Inject, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { REQUEST, RESPONSE } from "@nguniversal/express-engine/tokens";
import { isPlatformServer } from "@angular/common";

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageNotFoundComponent implements OnInit{

  constructor(@Optional() @Inject(REQUEST) private request: Request,
              @Optional() @Inject(RESPONSE) private response: Response,
              @Inject(PLATFORM_ID) private platformId: any) {
    this.response = response;
  }

  ngOnInit() {
    if (isPlatformServer(this.platformId) && this.response) {
      // @ts-ignore
      this.response.status(404);
    }
  }

}
