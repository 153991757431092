import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MatIconModule } from '@angular/material/icon';
import { EffectsModule } from '@ngrx/effects';
import { MatDialogModule } from "@angular/material/dialog";
import { DialogService, StorageProvider } from 'wt-front-shared';
import { StoreRouterConnectingModule } from "@ngrx/router-store";

import { coreReducer } from '@app/core/lib/+state/core.reducer';
import { services } from './services';
import { coreEffects } from '@app/state/core.effects';
import { coreFacades } from '@app/state/core.facades';

@NgModule({
  imports: [
    StoreModule.forRoot(coreReducer),
    EffectsModule.forRoot([...coreEffects]),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 5,
      logOnly: true
    }),
    MatIconModule,
    MatDialogModule,
  ],
  providers: [DialogService]
})
export class CoreModule {
  static forRoot() {
    return {
      providers: [
        ...coreFacades,
        ...services,
        //...coreReducerProvider,
        /*        {
                  provide: USER_PROVIDED_META_REDUCERS,
                  deps: [StorageService],
                  useFactory: getMetaReducers
                },*/
        StorageProvider
      ],
      ngModule: CoreModule
    };
  }
}

/*export function getMetaReducers(storageService: StorageService): MetaReducer<any>[] {
  return [(reducer) => initUserReducer(reducer/!*, storageService*!/)];
}*/
