<section class="error-404-section section-padding">
  <div class="error">
    <img src="assets/img/error404.png"/>
  </div>
  <div class="error-message">
    <h3>Ой! Страница не найдена!</h3>
    <p>К сожалению мы не можем найти запрошенную вами страницу. Возможно, вы неправильно ввели адрес.</p>
    <a href="http://uzbekistan-tours.ru" class="theme-btn">Вернуться назад</a>
  </div>
</section>
