import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  untilComponentDestroyed,
  WebsocketMessageSubTypes,
  WebsocketMessageTypes,
  WebsocketService
} from 'wt-front-shared';

import { NavigationEnd, Router } from "@angular/router";

@Component({
  selector: 'app-front-template',
  templateUrl: './front-template.component.html',
  styleUrls: ['./front-template.component.scss']
})
export class FrontTemplateComponent implements OnInit, OnDestroy {

  menuItems: { link: string, title: string }[] = [
    {link: '/', title: 'Main'},
    {link: '/pages/about', title: 'About'},
  ]

  constructor(private router: Router,
              private websocketService: WebsocketService
  ) {
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        untilComponentDestroyed(this)
      )
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          console.info('[ navigation ][ change-url ]', event.url);
          this.websocketService.wsSentMessage(WebsocketMessageTypes.USER_ACTIVITY, WebsocketMessageSubTypes.CHANGE_PAGE, event.url);

          window.scroll({
            top: 0,
            left: 0
          });
        }
      });
  }

  ngOnDestroy() {
  }

  toggleMenu(menu: HTMLElement): void {
    menu.classList.toggle("close-menu");
  }

}
